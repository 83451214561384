import {
    useState,
    useEffect
} from "react";

import { useDispatch } from "react-redux";

import {
    SaveButton,
    CancelButton,
    CheckBox,
    CheckBoxCollection,
    TextInput
} from "../components/Input";

import { 
    showMsg, 
    setLoadingNow,
    setLoadingComplete
} from "../store/pageSlice";

import { 
    GetGeneralSetup, 
    UpdateGeneralSetup
} from "../api/generalSetup";
import { GetLocations } from "../api/location";

import Help from "./Help";

import "../App.css";

export default function GeneralSetup() {
    const dispatch = useDispatch();

    const [dsGen, setDsGen] = useState(defaultGeneralSetup);
    const [dsLoc, setDsLoc] = useState([]);
    const [dsMasterLoc, setDsMasterLoc] = useState([]);

    const [checkedLoc, setCheckedLoc] = useState([]);

    const [isCancel, setIsCancel] = useState(false);
    
    const refreshData = async () => {
        dispatch(setLoadingNow());

        try {
            let dat = await GetGeneralSetup();

            dat = {
                ...dat,
                locations: dat.locations !== null ? dat.locations : []
            };

            setDsGen(dat);

            const checks = dat.locations.map((row) => row.id);

            setCheckedLoc(checks);

            const locDat = await GetLocations();

            setDsMasterLoc(locDat);

            const locLst = locDat.map((row) => ({
                id: row.id,
                label: row.locationCode
            }));

            setDsLoc(locLst);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to get general setup. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onFieldChange = (e, field) => {
        if (field === "RESTRICT_LOCATION") {
            setDsGen({
                ...dsGen,
                restrictLocation: e
            });
        } else if (field === "LATITUDE") {
            setDsGen({
                ...dsGen,
                latitude: e
            });
        } else if (field === "LONGITUDE") {
            setDsGen({
                ...dsGen,
                longitude: e
            });
        } else if (field === "MANDATORY_QR_CODE") {
            setDsGen({
                ...dsGen,
                mandatoryQRCode: e
            });
        } else if (field === "STATIC_QR_CODE") {
            setDsGen({
                ...dsGen,
                staticQRCode: e
            });
        } else if (field === "LOCATIONS") {
            setCheckedLoc(e);

            const checks = dsMasterLoc.filter((row) => {
                const i = e.findIndex((subrow) => subrow === row.id);
                return i > -1;
            });

            setDsGen({
                ...dsGen,
                locations: checks
            });
        } else if (field === "REQ_PIN_CODE") {
            setDsGen({
                ...dsGen,
                reqPinCode: e
            });
        }
    };

    const onSave = async (e) => {
        e.preventDefault();

        if (isCancel) {
            return;
        }

        dispatch(setLoadingNow());

        try {
            await UpdateGeneralSetup(dsGen);

            dispatch(showMsg({
                title: "Success",
                content: "Changes successfully saved!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save changes. ${ ex.message }`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    useEffect(() => {
        (
            async () => {
                await refreshData();
            }
        )();
    }, []);

    useEffect(() => {
        if (!isCancel) {
            return;
        }

        (
            async () => {
                await refreshData();
                setIsCancel(false);
            }
        )();
    }, [isCancel]);

    return (
        <>
            <strong className="title">General Setup</strong>
            <div className="panel-horizontal responsive"></div>
            <form className="general-setup-panel" onSubmit={ onSave }>
                <div className="form-row">
                    <CheckBox 
                        label="Mandatory QR Code" 
                        checked={ dsGen.mandatoryQRCode } 
                        onChange={() => { onFieldChange(!dsGen.mandatoryQRCode, "MANDATORY_QR_CODE") }} 
                    />
                </div>
                <div className="form-row">
                    <span>Geo-Fence Locations</span>
                </div>
                <div className="form-row">
                    <CheckBoxCollection 
                        style={{ height: "40vh" }} 
                        data={ dsLoc }
                        checked={ checkedLoc }
                        onSelectionChange={(e) => { onFieldChange(e, "LOCATIONS") }}
                    />
                </div>
                <div className="form-row form-row-border-top">
                    <CheckBox 
                        label="Restrict Location"
                        checked={ dsGen.restrictLocation }
                        onChange={() => { onFieldChange(!dsGen.restrictLocation, "RESTRICT_LOCATION") }}
                    />
                </div>
                <div className="form-row">
                    <TextInput label="Latitude" value={ dsGen.latitude } onChange={(e) => { onFieldChange(e, "LATITUDE") }}/>
                    <TextInput label="Longitude" value={ dsGen.longitude } onChange={(e) => { onFieldChange(e, "LONGITUDE") }} />
                </div>
                <div className="form-row">
                    <span>Security</span>
                </div>
                <div className="form-row">
                    <CheckBox 
                        label="Mandate PIN code authentication"
                        checked={ dsGen.reqPinCode }
                        onChange={() => { onFieldChange(!dsGen.reqPinCode, "REQ_PIN_CODE") }}
                    />
                </div>
                <div className="form-row form-row-border-top">
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton />
                        <CancelButton onClick={() => { setIsCancel(true) }}/>
                    </div>
                </div>
            </form>
            <Help sectionName="General Setup">
                <h3>What is General Setup?</h3>
                <p>
                    General setup is a set of default policy. This policy will be applied by default 
                    to un-assigned employees to any employee group.
                </p>
                <h3>How to Add New Geo-Fencing Location?</h3>
                <p>
                    Go to <strong>"Location"</strong> page to manage geo-fencing locations.
                </p>
                <h3>Mandate PIN code authentication</h3>
                <p>This option will enforce PIN code authentication for all iFlexi Clock users in your organization. If this option is enabled, 
                    the iFlexi Clock mobile app will prompt for PIN code authentication. If a user's device has biometric authentication integrated, they can opt in to use biometric authentication as the primary authentication method for iFlexi Clock.
                </p>
            </Help>
        </>
    );
}

const defaultGeneralSetup = {
    id: "-1",
    restrictLocation: false,
    latitude: "",
    longitude: "",
    iFlexiHRMSServer: "",
    mandatoryQRCode: false,
    staticQRCode: "",
    locations: [],
    reqPinCode: false
};