import { 
    useState,
    useEffect 
} from "react";

import { 
    useDispatch,
    useSelector
} from "react-redux";

import {
    Button,
    ComboBox,
    SaveButton,
    CancelButton,
    TextInput,
    CheckBoxCollection
} from "../components/Input";

import Help from "./Help";

import {
    GetTeams,
    UpsertTeam,
    DeleteTeam
} from "../api/team";

import { GetEmployeeMaster } from "../api/employeeMaster";

import {
    showMsg,
    setLoadingNow,
    setLoadingComplete
} from "../store/pageSlice";

import "../App.css"


export default function Team() {
    const dispatch = useDispatch();
    const pageSel = useSelector((state) => state.page);

    const [tab, setTab] = useState("DETAILS");
    
    const [ds, setDs] = useState([]);
    const [dsView, setDsView] = useState([]);

    const [dsEmp, setDsEmp] = useState([]);

    const [search, setSearch] = useState("");

    const [selTeam, setSelTeam] = useState(defaultTeam);

    const [isCancel, setIsCancel] = useState(false);
    const [isSwitchTab, setIsSwitchTab] = useState(false);

    const reload = async (data = []) => {
        dispatch(setLoadingNow());

        try {
            const teams = data.length === 0 ? await GetTeams() : data;
            let emps = await GetEmployeeMaster();

            emps = emps.sort((a, b) => {
                if (a.empNo < b.empNo) {
                    return -1;
                } else if (a.empNo > b.empNo) {
                    return 1;
                } else {
                    return 0;
                }
            });

            const dsTeam = [];

            teams.forEach((row) => {
                const empIndex = emps.findIndex((subRow) => subRow.empNo === row.supervisor);

                if (empIndex === -1) {
                    return;
                }

                let empData = emps[empIndex];

                if (empData !== null) {
                    let empResign = new Date(empData.resignDate);

                    if (empData.isSupervisor && (empResign.getTime() == new Date('0001-01-01T00:00:00.000Z').getTime() || empResign.getTime() >= new Date().getTime())) {
                        dsTeam.push({
                            supervisor: emps[empIndex],
                            team: row
                        });
                    }
                }
            });

            setDs(dsTeam);

            setDsView(dsTeam);

            setDsEmp(emps);

            setSelTeam(defaultTeam);
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to retrieve list. ${ex.message}`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onDelete = async () => {
        if (selTeam.team.id === "-1")
            return;

        dispatch(showMsg({
            title: "Deleting...",
            content: `Are you sure you want to delete team ${selTeam.supervisor.empNo} - ${selTeam.supervisor.name}?`,
            id: "DEL_TEAM",
            isPromptInput: true
        }));
    };

    const onSave = async (e) => {
        e.preventDefault();

        console.log(isSwitchTab);

        if (isCancel || isSwitchTab) {
            if (isSwitchTab)
                setIsSwitchTab(false)

            if (isCancel)
                setIsCancel(false);

            return;
        }

        try {
            dispatch(setLoadingNow());

            if (/[\w\d]+/.exec(selTeam.team.supervisor) === null || /[\w\d]+/.exec(selTeam.team.teamName) === null)
                throw new Error("Please select supervisor.");

            let teams;
            
            if (selTeam.team.id === "-1") {
                teams = await UpsertTeam({
                    ...selTeam.team,
                    id: null
                });
            } else {
                teams = await UpsertTeam(selTeam.team);
            }
            
            await reload(teams);

            dispatch(showMsg({
                title: "Success",
                content: "Changes has been successfully saved!"
            }));
        } catch (ex) {
            dispatch(showMsg({
                title: "Error",
                content: `Unable to save changes. ${ex.message}`
            }));
        } finally {
            dispatch(setLoadingComplete());
        }
    };

    const onSearch = () => {
        let dsViewTemp = ds.map((row) => row);

        if (search !== "") {
            const srch = search.toLowerCase();

            dsViewTemp = dsViewTemp.filter((row) => {
                const emps = row.team.employees.filter((subRow) => 
                    subRow.empNo.toLowerCase().indexOf(srch) > -1 || subRow.name.toLowerCase().indexOf(srch) > -1
                );

                return emps.length > 0 || 
                    row.supervisor.empNo.toLowerCase().indexOf(srch) > -1 || 
                    row.supervisor.name.toLowerCase().indexOf(srch) > -1;
            });
        }

        setDsView(dsViewTemp);
    };

    const supervisorOpts = () => {
        const opts = dsEmp
        .filter((row) => { 
            const teamIndex = ds.findIndex((subRow) => {
                return subRow.team.supervisor === row.empNo
            });

            let empResign = new Date(row.resignDate);

            if (empResign.getTime() !== new Date("0001-01-01T00:00:00.000Z").getTime() && empResign.getTime() <= new Date().getTime())
                return false;

            return row.isSupervisor && teamIndex < 0;
        })
        .map((row) => (
            <option value={row.empNo}>{`${row.empNo} - ${row.name}`}</option>
        ));

        opts.splice(0, 0, (<option value=""></option>));

        return opts;
    }

    const empCheckItems = () => dsEmp
            .map((row) => ({
                    id: row.empNo,
                    label: `${row.empNo} - ${row.name}`
                }));

    const empChecked = () => selTeam.team.employees
        .map((row) => row.empNo);

    const onMemberCheckChange = (e) => {
        let sel = [];

        for(let i = 0; i < e.length; i++) {
            const iRow = dsEmp.findIndex((subRow) => subRow.empNo === e[i]);

            if (iRow === -1)
                continue;

            sel.push({
                empNo: dsEmp[iRow].empNo,
                name: dsEmp[iRow].name
            });
        }

        setSelTeam({
            ...selTeam,
            team: {
                ...selTeam.team,
                employees: sel
            }
        });
    };

    const onCancel = () => {
        setIsCancel(true);

        if (selTeam.team.id === "-1")
            return;

        const i = ds.findIndex((row) => row.team.id === selTeam.team.id);

        if (i > -1) {
            setSelTeam({
                ...selTeam,
                team: {
                    ...selTeam.team,
                    employees: ds[i].team.employees
                }
            });
        }
    };

    const onSupervisorSelection = (row) => {
        setSelTeam(row);

        setIsSwitchTab(true); 

        setTab("DETAILS");
    };

    useEffect(() => {
        (
            async () => { await reload() }
        )();
    }, []);

    useEffect(onSearch, [search]);

    useEffect(() => {
        if (!pageSel.msgResult) {
            return;
        }

        (
            async () => {
                dispatch(setLoadingNow());

                try {
                    if (pageSel.msgId === "DEL_TEAM") {
                        const teams = await DeleteTeam(selTeam.team);
                        await reload(teams);
                    }
                } catch (ex) {
                    dispatch(showMsg({
                        title: "Error",
                        content: `Unable to proceed. ${ex.message}`
                    }));
                } finally {
                    dispatch(setLoadingComplete());
                }
            }
        )();
    }, [pageSel.msgResult]);

    useEffect(() => { setIsCancel(false) }, [selTeam]);

    return (
        <>
            <strong className="title">Team</strong>
            <div className="panel-horizontal">
                <div className="panel-inner panel-inner-start">
                    <Button src="/img/refresh.svg" onClick={() => { reload() }}/>
                    <Button label="New" src="/img/add.svg" onClick={() => { setSelTeam(defaultTeam) }}/>
                    <Button label="Delete" src="/img/remove.svg" onClick={onDelete}/>
                </div>
                <div className="panel-inner panel-inner-end"></div>
            </div>
            <form className="side-panel" onSubmit={onSave}>
                <div className="tab-container">
                    <button onClick={() => { setIsSwitchTab(true); setTab("DETAILS"); }}>Details</button>
                    <button onClick={() => { setIsSwitchTab(true); setTab("SUBORDINATE"); }}>Subordinate</button>
                </div>
                {
                    tab === "DETAILS" && (
                        <div className="form-row">
                            {
                                selTeam.team.id === "-1" && (
                                    <ComboBox 
                                        label="Supervisor" 
                                        value={selTeam.team.supervisor} 
                                        required
                                        opts={supervisorOpts()} 
                                        onChange={(e) => {
                                            const empIndex = dsEmp.findIndex((row) => row.empNo === e.target.value);
                                            
                                            setSelTeam({
                                                ...selTeam,
                                                supervisor: dsEmp[empIndex],
                                                team: {
                                                    ...selTeam.team,
                                                    supervisor: dsEmp[empIndex].empNo,
                                                    teamName: dsEmp[empIndex].empNo
                                                }
                                            })
                                        }} 
                                        />
                                )
                            }
                            {
                                selTeam.team.id !== "-1" && (
                                    <TextInput label="Supervisor" readonly value={selTeam.supervisor.name}/>
                                )
                            }
                        </div>
                    )
                }
                {
                    tab === "SUBORDINATE" && (
                        <>
                            <div className="form-section">
                                <strong>Subordinate</strong>
                            </div>
                            <div className="form-row">
                                <span>Select team members.</span>
                            </div>
                            <div className="form-row">
                                <CheckBoxCollection search style={{ height: "42vh" }} data={empCheckItems()} checked={empChecked()} onSelectionChange={(e) => { onMemberCheckChange(e) }} />
                            </div>
                        </>
                    )
                }
                <div className="form-row form-row-border-top">
                    <div className="form-row" style={{ padding: 0 }}>
                        <SaveButton /> 
                        <CancelButton onClick={onCancel}/>
                    </div>
                </div>
            </form>
            <div className="listing">
                <input className="listing-search-box" type="text" placeholder="search" value={ search } onChange={(e) => { setSearch(e.target.value) }} />
                <div>
                    {
                        dsView.map((row) => (
                            <div key={row.team.id} className="listing-row" onClick={() => { onSupervisorSelection(row) }}>
                                <strong>{row.team.supervisor}</strong>
                                <span>{ row.supervisor.name }</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Help sectionName="Team">
                <h3>Create New Team</h3>
                <ul>
                    <li>Click on <strong>"New"</strong> button.</li>
                    <li>Select the supervisor. If supervisor is not appear in the list, go to <strong>"Employee Master"</strong>, 
                    to the supervisor employee data and enable <strong>"Supervisor"</strong> option. Once enable, go back to <strong>"Team"</strong>.</li>
                    <li>To add subordinate, go to <strong>"Subordinate"</strong> tab.</li>
                    <li>Click <strong>"Save"</strong> to save changes.</li>
                </ul>
                <h3>Delete Team</h3>
                <ul>
                    <li>Click on desired record.</li>
                    <li>Click on <strong>"Delete"</strong> button.</li>
                </ul>
            </Help>
        </>
    );
}

const defaultTeam = {
    supervisor: {
        empNo: "",
        name: ""
    },
    team: {
        supervisor: "",
        teamName: "",
        employees: [],
        id: "-1"
    }
};
